/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-13 14:31:03
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-13 15:13:35
 */
export const columns = [
{
    title: '权限名称',
    dataIndex: 'menuName',
    key: 'menuName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '路由唯一键',
    dataIndex: 'menuKey',
    key: 'menuKey',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '组件',
    dataIndex: 'menuKey',
    key: 'menuKey',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '排序',
    dataIndex: '',
    key: '',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '菜单类型',
    dataIndex: 'menuType',
    key: 'menuType',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '链接',
    dataIndex: 'path',
    key: 'path',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '重定向',
    dataIndex: 'redirect',
    key: 'redirect',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '权限标识',
    dataIndex: 'perms',
    key: 'perms',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '状态',
    dataIndex: 'visible',
    key: 'visible',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
